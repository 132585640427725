<template>
	<div class="login-container">
		<div class="login-content">
			<img src="../../assets/login/ys.png" class="l-ys">
			<img src="../../assets/login/zx.png" class="l-zx">
			<img src="../../assets/login/logo.png" style="width: 8.5rem; height: 2rem;">
			<div style="color: #8C8C8C; margin-top: .7rem; font-size: .8rem;">国际云转售平台</div>
			<div
				style="margin-top: 2.1rem; color: #3B91FF; width: 19.2rem; padding-bottom: .6rem; border-bottom: .1rem solid #3B91FF;text-align: center;">
				账户登录</div>
			<el-form :model="loginForm" :rules="rules" ref="loginForm" style="margin-top: 1.3rem;">
				<el-form-item prop="username">
					<el-input placeholder="账户" style="width: 19.2rem;" type="text" v-model="loginForm.username">
						<img src="../../assets/login/user.png" slot="prefix"
							style="width: .9rem; height: .9rem;margin-left: .3rem;">
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input placeholder="密码" style="width: 19.2rem;" :type="!showPass?'password':'text'"
						v-model="loginForm.password">
						<img src="../../assets/login/password.png" slot="prefix"
							style="width: .9rem; height: .9rem;margin-left: .3rem;">
						<img src="../../assets/login/showpass.png" slot="suffix"
							style="width: .9rem; height: .9rem;margin-right: .3rem;cursor: pointer;"
							@click="showPass = true" v-if="!showPass">
						<img src="../../assets/login/hidepass.png" slot="suffix"
							style="width: .9rem; height: .9rem;margin-right: .3rem;cursor: pointer;"
							@click="showPass = false" v-if="showPass">
					</el-input>
				</el-form-item>
				<el-form-item class="bo-item">
					<el-button style="background-color: #3B91FF; width: 19.2rem; margin-top: .5rem;" type="primary"
						@click="submitForm('loginForm')">登 录</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		login
	} from '../../api/login.js'
	import VueCookies from 'vue-cookies'
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入用户名'));
				} else {
					if (this.loginForm.username !== '') {
						this.$refs.loginForm.validateField('checkPass');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					callback();
				}
			};
			return {
				loginForm: {
					username: '',
					password: '',
				},
				rules: {
					username: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					password: [{
						validator: validatePass2,
						trigger: 'blur'
					}]
				},
				showPass: false
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						login({
							username: this.loginForm.username,
							password: this.loginForm.password
						}).then(res => {
							if (res.data.err_code == "ACC001") {
								this.$message.error(res.data.err_msg)
							} else {
								if(res.data.err_code == "ACC004"){
									this.$message.warning(res.data.err_msg)
								}else{
									res.data.userName = this.loginForm.username
									this.$store.commit("SET_USERINFO", res.data)
									VueCookies.set("userInfo",res.data)
									console.log(VueCookies.get("userInfo"))
									this.$message.success("登录成功")
									localStorage.setItem('username',this.loginForm.username)
									this.$router.push("/")
								}
							}
							console.log(res)
						})
						console.log("登录")
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		},
		created() {
			this.loginForm.username = localStorage.getItem('username') || ""
		}
	}
</script>

<style lang="scss">
	.login-container {
		background-color: #E7ECF2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-size: 100% 100%;
		padding-right: 17.6rem;
		background-image: url(../../assets/login/back.png);
		box-sizing: border-box;

		.login-content {
			width: 23.6rem;
			padding: 5.3rem 0 7.4rem 0;
			position: relative;
			background-color: #ffffff;
			box-shadow: .2rem .2rem 1.5rem rgba(0, 69, 155, 0.1);
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 4.5rem;

			.l-ys {
				width: 6.6rem;
				height: 6.1rem;
				position: absolute;
				top: 0;
				right: 0;
			}

			.l-zx {
				width: 8.4rem;
				height: 6.3rem;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			.bo-item{
				margin-bottom: 0;
				.el-button{
					height: 2.3rem;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: .8rem;
					border-radius: .2rem;
				}
			}
		}

		.el-input__prefix {
			display: flex;
			align-items: center;
		}

		.el-input__suffix {
			display: flex;
			align-items: center;
		}

		.el-input__suffix-inner {
			display: flex;
			align-items: center;
		}

		.el-form-item {
			margin-bottom: 2.3rem;
		}

		// .el-input__suffix-inner{
		// 	display: flex;
		// 	align-items: center;
		// }
	}
</style>
