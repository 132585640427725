import request from '../request.js'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
Vue.use(VueCookies)

export const login = (data)=>{
	return new Promise(resolve=>{
		request({
			url:"user/login/",
			method:"post",
			data
		}).then(res=>{
			VueCookies.set('token',res.data)
			resolve(res)
		})
	})
}